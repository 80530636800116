<template>
  <div class="flex w-screen h-screen overflow-hidden">
    <div class="pref bg-purple-600 w-1/2 overflow-hidden">
      <app-logo invert />

      <h2 class="unicorn-h2 text-white mt-8">Здравствуйте!</h2>

      <p class="mt-4 text-lg text-purple-300">
        Личный кабинет <span class="text-white font-bold">я.учитель</span> —
        центральный хаб управления питанием учеников, анализа посещения и
        температурного мониторинга
      </p>

      <div class="preview">
        <img src="../shared/assets/images/figures-2.png" alt="" />
      </div>
    </div>

    <div class="w-3/5 flex p-4 fd">
      <div class="m-auto max-w-sm w-full">
        <u-hide-at breakpoint="lg">
          <app-logo class="mb-8" />
        </u-hide-at>

        <h4 class="unicorn-h4">Вход в личный кабинет</h4>
        <h1 class="unicorn-h1 mb-12">я.учитель</h1>

        <template v-if="isExternalSite">
          <form class="flex flex-col" @submit.prevent="authWithToken($event)">
            <u-text-field
              :value="token.trim().toUpperCase()"
              @input="token = $event.trim().toUpperCase()"
              label="Введите токен авторизации"
            />

            <u-button role="submit" class="mt-4" variant="primary" block>
              Войти
            </u-button>
          </form>

          <u-divider style="margin: 2rem 0"> или </u-divider>

          <qr-code-scanner @qrcode="authWithQrCode" />
        </template>

        <template v-else>
          <form class="flex flex-col" @submit.prevent="auth">
            <u-text-field v-model="username" label="Логин, имя пользователя" />

            <u-text-field
              class="mt-4"
              type="password"
              v-model="password"
              label="Пароль"
            />

            <u-alert
              v-if="status.name === 'error'"
              variant="danger"
              class="mt-4"
            >
              Ошибка авторизации. Попробуйте позднее
            </u-alert>

            <u-button class="mt-4" variant="primary" block> Войти </u-button>
          </form>
        </template>

        <mip-logo class="mt-12" />
      </div>
    </div>
  </div>
</template>

<script>
import Api from "../services/Api";
import AppLogo from "@/app/shared/new-component/AppLogo";
import MipLogo from "@/app/shared/new-component/MipLogo";
import QrCodeScanner from "@/app/shared/new-component/QrCodeScanner";
import { isExternalSite } from "../services/environment";

export default {
  components: { QrCodeScanner, MipLogo, AppLogo },

  data() {
    return {
      username: "",
      password: "",
      token: "",
      status: {
        name: "success",
        data: null
      }
    };
  },

  computed: {
    isExternalSite() {
      return isExternalSite();
    }
  },

  mounted() {
    const token = this.$route.query.token;

    if (token) {
      this.authWithToken(null, token);
    }
  },

  methods: {
    async auth() {
      const { username, password } = this;

      if (!(username.trim() && password.trim())) return;

      try {
        this.setStatus("pending");
        const { data: profile } = await Api.authorization.login(
          username.trim(),
          password.trim()
        );
        this.setStatus("success");

        sessionStorage.setItem("profile", JSON.stringify(profile));
        await this.$router.push({ name: "home" });
      } catch ({ response }) {
        if (!response) return this.setStatus("error", -1);
        this.setStatus(
          "error",
          response.status && Math.floor(response.status / 100)
        );
      }
    },

    authWithQrCode(qrcode) {
      const token = qrcode.toString().split("?token=")[1];
      this.authWithToken(null, token);
    },

    async authWithToken(e, token) {
      try {
        this.setStatus("pending");
        const { data: profile } = await Api.authorization.loginWithToken(
          token || this.token.trim()
        );
        this.setStatus("success");

        sessionStorage.setItem("profile", JSON.stringify(profile));
        await this.$router.push({ name: "home" });
      } catch ({ response }) {
        if (!response) return this.setStatus("error", -1);
        this.setStatus(
          "error",
          response.status && Math.floor(response.status / 100)
        );
      }
    },

    setStatus(name, data) {
      this.status = { name, data };
    }
  }
};
</script>

<style scoped>
.preview {
  position: fixed;
  top: 270px;
  left: -370px;
  width: 1380px;
  pointer-events: none;
}

.pref {
  @apply p-20;
}

@media screen and (max-width: 1200px) {
  .preview {
    left: -50%;
  }

  .pref {
    @apply p-10;
  }
}

@media screen and (max-width: 1024px) {
  .pref {
    @apply hidden;
  }

  .fd {
    @apply w-full;
  }
}
</style>
